import Vue from 'vue'

export default {

    /**
     * ... first decide what the Doppelgänger, Mirage, and Shapeshifter
     * are, in that order. Then use the Book of Changes. Then follow any instructions
     * which call for the clearing of a penalty or part of a penalty. Finally, apply all
     * penalties, beginning with cards not blanked by any other cards
     */
    applyScoring({hand, discard, players}) {
        for (let card of hand) {
            Vue.set(card, 'scoreBonus', 0)
            Vue.set(card, 'scoreBaseStrength', card.baseStrength)
            Vue.set(card, 'scoreSuit', card.suit)
            Vue.set(card, 'scoreName', card.name)
            Vue.set(card, 'blanked', false)
            Vue.set(card, 'penaltiesCleared', false)
            Vue.set(card, 'armyPenaltyCleared', false)
            Vue.set(card, 'scoringInfos', [])
            if (!Object.prototype.hasOwnProperty.call(card, 'order'))
                Vue.set(card, 'order', 999)
            Vue.set(card, 'unblankable', false)
            if (!Object.prototype.hasOwnProperty.call(card, 'blanks'))
                Vue.set(card, 'blanks', () => [])
            Vue.set(card, 'blank', function () {
                this.blanked = true
                this.scoreBaseStrength = 0
                this.scoreSuit = undefined
                this.scoreName = undefined
            })
        }
        this.applyRelations(hand)
        this.clearPenalties(hand)
        let cardsBlankedByOthers = new Set()
        let mutuallyBlanked = new Set()
        for (let card of hand) {
            for (let blankedCard of card.blanks(hand)) {
                if (blankedCard.blanks(hand).includes(card)) {
                    mutuallyBlanked.add(card)
                    mutuallyBlanked.add(blankedCard)
                }
                if (!cardsBlankedByOthers.has(blankedCard)) {
                    cardsBlankedByOthers.add(blankedCard)
                }
            }
        }
        this.applyPenalties(hand, cardsBlankedByOthers, mutuallyBlanked)
        this.applyBonuses(hand, discard, players)
    },

    score(hand, cursedItems) {
        let res = 0
        hand.forEach(card => res += (card.scoreBaseStrength + card.scoreBonus))
        if(cursedItems?.length > 0)
            cursedItems.forEach(ci => res += ci.points)
        return res
    },

    applyRelations(hand) {
        let cardsWithRelations = hand.filter(c => c.relationType !== 'none')
        cardsWithRelations.sort((c1, c2) => c1.order - c2.order)
        for (let card of cardsWithRelations) {
            // check if related cards are still valid
            if (card.relatedCard) {
                if (card.relationType !== 'gameCard' &&
                    (!hand.find(c => c === card.relatedCard)
                        || (card.relationSuits && !card.relationSuits.find(suit => suit === card.relatedCard.scoreSuit)))) {
                    card.relatedCard = null;
                }
            }
            card.applyRelation?.(hand)
        }
    },

    clearPenalties(hand) {
        for (let card of hand) {
            if (card.clearPenalties !== undefined)
                card.clearPenalties(hand)
        }
    },

    applyPenalties(hand, cardsBlankedByOthers, mutuallyBlankedCards) {
        let toBeProcessed = new Set(hand)
        // first apply blanking of the Demon (blanksFirst === true)
        hand.filter(c => c.blanksFirst).forEach(c => {
            this.applyPenalty(c, hand, mutuallyBlankedCards)
            toBeProcessed.delete(c)
        })
        // apply all penalties, beginning with cards not blanked by any other cards
        for (let card of hand.filter(c => !cardsBlankedByOthers.has(c) || mutuallyBlankedCards.has(c))) {
            this.applyPenalty(card, hand, mutuallyBlankedCards)
            toBeProcessed.delete(card)
        }
        for (let card of toBeProcessed)
            this.applyPenalty(card, hand)
    },

    applyPenalty(card, hand, mutuallyBlankedCards) {
        if ((!card.blanked || mutuallyBlankedCards?.has(card)) && !card.penaltiesCleared && card.applyPenalties !== undefined) {
            card.applyPenalties(hand)
        }
    },

    applyBonuses(hand, discard, players) {
        for (let card of hand) {
            if (!card.blanked && card.applyBonus !== undefined)
                card.applyBonus({hand, discard, players})
        }
    }
}