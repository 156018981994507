<template>
  <div>
    <icon v-if="$route.name === 'addToPile2'"
          class="navigate-item"
          icon="angle-double-up"
          size="2x"
          @click="$router.push({name:'viewPiles'})"/>
    <icon v-else-if="$route.name === 'viewPiles'"
          class="navigate-item"
          icon="angle-double-down"
          size="2x"
          @click="$router.push({name:'addToPile2'})"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(['cardsInSelectedPile'])
  }
}
</script>
<style scoped>
.navigate-item {
  cursor: pointer;
}

@media screen and (min-height: 600px){
  .navigate-item {
    width: 60px;
    height: 60px;
  }
}
</style>