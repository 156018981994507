<script>
import {TYPE} from "vue-toastification";

export default {
  methods: {
    showInfo(message) {
      this.showMessage({
        message: message,
        type: 'info'
      })
    },
    showError(message) {
      this.showMessage({
        message: message,
        type: 'danger'
      })
    },
    showWarning(message) {
      this.showMessage({
        message: message,
        type: 'warning'
      })
    },
    showMessage(message) {
      if (message != null) {
        let type;
        type = TYPE.SUCCESS;
        if (message.type === 'danger')
          type = TYPE.ERROR
        if (message.type === 'warning')
          type = TYPE.WARNING
        this.$toast(message.message, {
          type: type
        })
      }
    }
  }
}
</script>