<template>
  <div id="app">
    <router-view
        style="height: 100vh; width: 100%; min-height: 100vh; position: absolute; top: 0;"/>
    <div class="micro" v-if="selectedPile"
         @click="recording = !recording"
         :class="{recording}">
      <VoiceRecognition
          v-if="1 === 2"
          :recording="recording"
          @end="recording = false"
          @recognized="cardNameRecognized($event)"/>
    </div>
    <navigator class="navigator"/>
    <please-rotate/>
    <cookie-consent/>
  </div>
</template>
<script>
import getBrowserLocale from "@/utils/get-browser-locale";
import PleaseRotate from "@/components/PleaseRotate"
import Navigator from "@/components/Navigator";
import CookieConsent from "@/components/CookieConsent";
import {mapActions, mapGetters} from "vuex";
import VoiceRecognition from "@/components/VoiceRecognition";
import {bootstrap} from "vue-gtag";

export default {
  components: {VoiceRecognition, Navigator, PleaseRotate, CookieConsent},
  metaInfo() {
    return {
      title: this.$t('siteTitle'),
      themeColor: '#6c1010'
    }
  },
  data() {
    return {
      recording: false
    }
  },
  computed: {
    ...mapGetters(['selectedPile', 'trackingConsent'])
  },
  methods: {
    ...mapActions(['init', 'addCardToPile']),
    async cardNameRecognized(cardName) {
      await this.addCardToPile({cardName, pile: this.selectedPile})
          .catch(error => {
            this.recording = false
            this.showWarning(error.message)
          })
    }
  },
  watch: {
    trackingConsent: {
      immediate: true,
      handler(consent) {
        if (consent) {
          bootstrap().then(() => {
            console.log('Google Analytics tracking turned on.')
          })
        }
      }
    }
  },
  mounted() {
    this.$i18n.locale = getBrowserLocale({countryCodeOnly: true})
    if (!['de', 'en'].includes(this.$i18n.locale))
      this.$i18n.locale = 'en'
    window.addEventListener("keydown", e => {
      if (e.key == 'Escape')
        this.$store.dispatch('selectCard', null, {root: true})
    })
    this.init()
  }
  ,
}
</script>

<style scoped>
#app {
  position: absolute;
  height: 100%;
  min-width: 100%;
  max-width: 100%;
}

.navigator {
  position: absolute;
  bottom: 1%;
  right: 1%;
}

.micro {
  position: absolute;
  bottom: 2%;
  right: 9%;
}

.recording {
  color: red
}

</style>
