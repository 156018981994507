import i18n from "@/i18n";

const MAX_PLAYERS = 6
const MIN_PLAYERS = 2
const findPlayerByNumber = (players, playerNumber) => {
    return players.find(p => p.number === playerNumber)
}

export default {
    state: {
        players: [],
        selectedPlayerNumber: 0
    },
    mutations: {
        players(state, players) {
            state.players = players
        },
        selectedPlayerNumber(state, selectedPlayerNumber) {
            state.selectedPlayerNumber = selectedPlayerNumber
        }
    },
    actions: {
        async reset({commit, dispatch, getters}) {
            commit('players', [])
            let lastGame = getters.lastGame
            if (lastGame) {
                for (let lastGamePlayer of lastGame.players) {
                    await dispatch('addPlayer', lastGamePlayer.name)
                }
            } else {
                await dispatch('addPlayer', null)
                await dispatch('addPlayer', null)
            }
            dispatch('selectPlayer', 1)
        },
        async addPlayer({dispatch, commit, state}, name) {
            if (state.players.length === MAX_PLAYERS) {
                return
            }
            let number = state.players.length + 1
            let player = {
                number,
                name: name || i18n.t('player') + ' ' + number,
                score: 0
            }
            while (state.players.find(p => p.name === player.name)) {
                number++
                player.name = i18n.t('player') + ' ' + number
            }
            state.players.push(player)
            commit('players', state.players)
            await dispatch('playerAdded', state.players, {root: true})
            await dispatch('updateAllScores', null, {root: true})
            dispatch('selectPlayer', player.number)
        },
        async removePlayer({commit, dispatch, state}, playerNumber) {
            let players = state.players
            if (players.length === MIN_PLAYERS)
                return
            players.splice(playerNumber - 1, 1)
            players.slice(playerNumber - 1).forEach(p => p.number = p.number - 1)
            commit('players', players)
            await dispatch('playerRemoved', playerNumber, {root: true})
            if (state.selectedPlayerNumber === playerNumber)
                await dispatch('selectPlayer', Math.max(1, playerNumber - 1))
        },
        selectPlayer({commit}, playerNumber) {
            commit('selectedPlayerNumber', playerNumber)
        },
        selectDiscard({dispatch}) {
            dispatch('selectPlayer', 0)
        },
        setPlayerScore({commit, state}, {player, score}) {
            let players = state.players
            players.find(p => p.number === player).score = score
            commit('players', players)
        },
        setPlayerName({commit, state}, {number, name}) {
            let players = state.players
            players.find(p => p.number === number).name = name
            commit('players', players)
        }
    },
    getters: {
        players(state) {
            return state.players
        },
        selectedPlayer(state) {
            return findPlayerByNumber(state.players, state.selectedPlayerNumber)
        },
        maxPlayers() {
            return MAX_PLAYERS
        },
        minPlayers() {
            return MIN_PLAYERS
        }
    }
}
