import i18n from '@/i18n';

export const decks = {Basic: 'basic', CursedHoard: 'cursedHoard'}
export const suits = {
    Army: 'Army',
    Leader: 'Leader',
    Wizard: 'Wizard',
    Weapon: 'Weapon',
    Artifact: 'Artifact',
    Beast: 'Beast',
    Land: 'Land',
    Weather: 'Weather',
    Flood: 'Flood',
    Flame: 'Flame',
    Wild: 'Wild',
    Building: 'Building',
    Outsider: 'Outsider',
    Undead: 'Undead'
}

let Mountain = {
    name: "Mountain",
    suit: suits.Land,
    baseStrength: 9,
    relationType: "none",
    clearPenalties(hand) {
        for (let card of hand.filter(c => c.scoreSuit === suits.Flood)) {
            card.penaltiesCleared = true
            addScoringInfo('clearsPenalty', this, card)
        }
    },
    applyBonus({hand}) {
        let smoke = findCardInHand(hand, cards.Smoke)
        let wildFire = findCardInHand(hand, cards.Wildfire)
        if (smoke && wildFire) {
            this.scoreBonus += 50
            addScoringInfo('gainsBy2Cards', this, smoke, 50, null, wildFire)
        }
    }
}
let UndergroundCaverns = {
    name: "Cavern",
    suit: suits.Land,
    baseStrength: 6,
    relationType: "none",
    clearPenalties(hand) {
        for (let c of hand) {
            if (c.hasPenalty && c.scoreSuit === suits.Weather && c.hasPenalty) {
                c.penaltiesCleared = true
                addScoringInfo('clearsPenalty', this, c)
            }
        }
    },
    applyBonus({hand}) {
        for (let c of hand) {
            if (c.scoreName === cards.DwarvishInfantry.name
                || c.scoreName === cards.Dragon.name) {
                this.scoreBonus = 25
                addScoringInfo('gainsByCard', this, c, 25)
                return
            }
        }
    }
}
let BellTower = {
    name: "BellTower",
    suit: suits.Land,
    deck: decks.Basic,
    baseStrength: 8,
    relationType: "none",
    applyBonus({hand}) {
        for (let c of hand) {
            if (c.scoreSuit === suits.Wizard) {
                this.scoreBonus = 15
                addScoringInfo('gainsBySuit', this, c, 15, suits.Wizard)
                return
            }
        }
    }
}
let Forest = {
    name: "Forest",
    suit: suits.Land,
    baseStrength: 7,
    relationType: "none",
    applyBonus({hand}) {
        for (let c of hand) {
            if (c.scoreName === cards.ElvenArchers.name
                || c.scoreSuit === suits.Beast) {
                this.scoreBonus += 12
                addScoringInfo(c.scoreSuit === suits.Beast ? 'gainsBySuit' : 'gainsByCard', this, c, 12, suits.Beast)
            }
        }
    }
}
let EarthElemental = {
    name: "EarthElemental",
    suit: suits.Land,
    baseStrength: 4,
    relationType: "none",
    applyBonus({hand}) {
        for (let c of hand) {
            if (c.scoreSuit === suits.Land && c !== this) {
                this.scoreBonus += 15
                addScoringInfo('gainsBySuit', this, c, 15, suits.Land)
            }
        }
    }
}
let Garden = {
    name: 'Garden',
    suit: 'Land',
    deck: decks.CursedHoard,
    baseStrength: 11,
    hasPenalty: true,
    relationType: 'none',
    blanks(hand) {
        if (this.unblankable)
            return []
        let necromancer = findCardInHand(hand, Necromancer)
        let demon = findCardInHand(hand, Demon)
        if (necromancer || demon) {
            return [this]
        }
        for (let card of hand) {
            if (card.scoreSuit === suits.Undead)
                return [this]
        }
        return []
    },
    applyPenalties(hand) {
        if (this.blanks(hand).length > 0) {
            this.blank()
            let necromancer = findCardInHand(hand, Necromancer)
            let demon = findCardInHand(hand, Demon)
            if (necromancer || demon) {
                addScoringInfo('blankedByCard', this, necromancer || demon)
            } else {
                addScoringInfo('blankedBySuit', this, hand.filter(c => c.scoreSuit === suits.Undead)[0], 0, suits.Undead)
            }
        }
    },
    applyBonus({hand}) {
        let bonus = 0
        for (let card of hand) {
            if (card.scoreSuit === suits.Leader || card.scoreSuit === suits.Beast) {
                bonus += 11
                addScoringInfo('gainsBySuit', this, card, 11, card.scoreSuit)
            }
        }
        this.scoreBonus = bonus
    }

}

let FountainOfLife = {
    name: "FountainOfLife",
    suit: suits.Flood,
    deck: decks.Basic,
    baseStrength: 1,
    relationType: "handCard",
    relationToSelf: true,
    relationSuits: [suits.Weapon, suits.Flood, suits.Flame, suits.Land, suits.Weather],
    applyBonus() {
        if (this.relatedCard) {
            this.scoreBonus = this.relatedCard.scoreBaseStrength
            addScoringInfo('gainsByBaseStrength', this, this.relatedCard, this.relatedCard.baseStrength)
        }
    }
}
let Swamp = {
    name: "Swamp",
    suit: "Flood",
    baseStrength: 18,
    hasPenalty: true,
    hasArmyPenalty: true,
    relationType: "none",
    applyPenalties(hand) {
        for (let card of hand) {
            if ((card.scoreSuit === suits.Army && !this.armyPenaltyCleared)
                || card.scoreSuit === suits.Flame) {
                this.scoreBonus -= 3
                addScoringInfo('loosesBySuit', this, card, -3, card.scoreSuit)
            }
        }
    }
}
let GreatFlood = {
    name: "GreatFlood",
    suit: suits.Flood,
    deck: decks.Basic,
    baseStrength: 32,
    hasPenalty: true,
    hasArmyPenalty: true,
    relationType: "none",
    blanks(hand) {
        if (this.penaltiesCleared)
            return []
        return hand.filter(card =>
            ((card.scoreSuit === suits.Army && !this.armyPenaltyCleared)
                || (card.scoreSuit === suits.Land && card.scoreName !== Mountain.name)
                || (card.scoreSuit === suits.Flame && card.scoreName !== Lightning.name)))
    },
    applyPenalties(hand) {
        for (let card of this.blanks(hand)) {
            addScoringInfo('blanksBySuit', this, card, 0, card.scoreSuit)
            card.blank()
        }
    }
}
let GreatFloodCH = {
    ...GreatFlood,
    imgName: 'GreatFloodCH',
    deck: decks.CursedHoard,
    blanks(hand) {
        let blankedByBaseCard = GreatFlood.blanks.call(this, hand)
        let additionallyBlanked = this.penaltiesCleared ? [] : hand.filter(c => c.scoreSuit === suits.Building)
        return [...blankedByBaseCard, ...additionallyBlanked]
    }
}
let Island = {
    name: "Island",
    suit: suits.Flood,
    baseStrength: 14,
    relationType: "handCard",
    relationSuits: [suits.Flood, suits.Flame],
    relationToSelf: true,
    clearPenalties() {
        if (this.relatedCard && this.relatedCard.hasPenalty) {
            this.relatedCard.penaltiesCleared = true
            addScoringInfo('clearsPenalty', this, this.relatedCard)
        }
    }
}
let WaterElemental = {
    name: "WaterElemental",
    suit: suits.Flood,
    baseStrength: 4,
    relationType: "none",
    applyBonus({hand}) {
        for (let card of hand) {
            if (card.scoreSuit === suits.Flood && card !== this) {
                this.scoreBonus += 15
                addScoringInfo('gainsBySuit', this, card, 15, suits.Flood)
            }
        }
    }
}
let FountainOfLifeCH = {
    ...FountainOfLife,
    deck: decks.CursedHoard,
    imgCard: 'FountainOfLifeCH',
    relationSuits: [...FountainOfLife.relationSuits, suits.Building]
}

let Rainstorm = {
    name: "Rainstorm",
    suit: suits.Weather,
    baseStrength: 8,
    hasPenalty: true,
    relationType: "none",
    blanks(hand) {
        if (this.penaltiesCleared)
            return []
        return hand.filter(card => card.scoreSuit === suits.Flame && card.scoreName !== Lightning.name)
    },
    applyBonus({hand}) {
        for (let card of hand) {
            if (card.scoreSuit === suits.Flood) {
                this.scoreBonus += 10
                addScoringInfo('gainsBySuit', this, card, 10, suits.Flood)
            }
        }
    },
    applyPenalties(hand) {
        for (let card of this.blanks(hand)) {
            addScoringInfo('blanksBySuit', this, card, 0, card.scoreSuit)
            card.blank()
        }
    }
}
let Blizzard = {
    name: "Blizzard",
    suit: suits.Weather,
    baseStrength: 30,
    hasPenalty: true,
    hasArmyPenalty: true,
    relationType: "none",
    blanks(hand) {
        if (this.penaltiesCleared)
            return []
        return hand.filter(card => card.scoreSuit === suits.Flood)
    },
    applyPenalties(hand) {
        for (let card of this.blanks(hand)) {
            card.blank()
            addScoringInfo('blanksBySuit', this, card)
        }
        for (let card of hand) {
            if ((card.scoreSuit === suits.Army && !this.armyPenaltyCleared)
                || card.scoreSuit === suits.Leader
                || card.scoreSuit === suits.Beast
                || card.scoreSuit === suits.Flame) {
                this.scoreBonus -= 5
                addScoringInfo('loosesBySuit', this, card, -5, card.scoreSuit)
            }
        }
    }
}
let Smoke = {
    name: "Smoke",
    suit: suits.Weather,
    hasPenalty: true,
    baseStrength: 27,
    relationType: "none",
    applyPenalties(hand) {
        if (hand.filter(card => card.scoreSuit === suits.Flame).length === 0) {
            addScoringInfo('requiredSuitForUnblockMissing', this, null, 0, suits.Flame)
            this.blank()
        }
    }
}
let Whirlwind = {
    name: "Whirlwind",
    suit: suits.Weather,
    baseStrength: 13,
    relationType: "none",
    applyBonus({hand}) {
        let rainStorm = findCardInHand(hand, Rainstorm)
        if (rainStorm) {
            let blizzard = findCardInHand(hand, Blizzard)
            let greatFlood = findCardInHand(hand, GreatFlood)
            if (blizzard || greatFlood) {
                this.scoreBonus += 40
                addScoringInfo('gainsBy2Cards', this, rainStorm, 40, null, blizzard || greatFlood)
            }
        }
    }
}
let AirElemental = {
    name: "AirElemental",
    suit: suits.Weather,
    baseStrength: 4,
    relationType: "none",
    applyBonus({hand}) {
        for (let card of hand) {
            if (card !== this && card.scoreSuit === suits.Weather) {
                this.scoreBonus += 15
                addScoringInfo('gainsBySuit', this, card, 15, suits.Weather)
            }
        }
    }
}

let Wildfire = {
    name: "Wildfire",
    suit: suits.Flame,
    hasPenalty: true,
    baseStrength: 40,
    relationType: "none",
    blanks(hand) {
        if (this.penaltiesCleared)
            return []
        return hand.filter(card => !card.unblankable && (
            card.scoreSuit !== suits.Flame
            && card !== this
            && card.scoreSuit !== suits.Artifact
            && card.scoreSuit !== suits.Wizard
            && card.scoreSuit !== suits.Weather
            && card.scoreSuit !== suits.Weapon
            && card.scoreName !== Mountain.name
            && card.scoreName !== GreatFlood.name
            && card.scoreName !== Island.name
            && card.scoreName !== Unicorn.name
            && card.scoreName !== Dragon.name))
    },
    applyPenalties(hand) {
        this.blanks(hand).forEach(card => {
            addScoringInfo('blanks', this, card)
            card.blank()
        })
    }
}
let Candle = {
    name: "Candle",
    suit: suits.Flame,
    baseStrength: 2,
    relationType: "none",
    applyBonus({hand}) {
        let bookOfChanges = findCardInHand(hand, BookOfChanges)
        let bellTower = findCardInHand(hand, BellTower)
        if (bookOfChanges && bellTower) {
            let wizard = hand.find(c => c.scoreSuit === suits.Wizard)
            if (wizard) {
                this.scoreBonus += 100
                addScoringInfo('gainsBy3Cards', this, bookOfChanges, 100, null, bellTower, wizard)
            }
        }
    }
}
let Forge = {
    name: "Forge",
    suit: suits.Flame,
    baseStrength: 9,
    relationType: "none",
    applyBonus({hand}) {
        for (let card of hand) {
            if (card.scoreSuit === suits.Weapon
                || card.scoreSuit === suits.Artifact) {
                this.scoreBonus += 9
                addScoringInfo('gainsBySuit', this, card, 9, card.scoreSuit)
            }
        }
    }
}
let Lightning = {
    name: "Lightning",
    suit: suits.Flame,
    baseStrength: 11,
    relationType: "none",
    applyBonus({hand}) {
        let rainstorm = findCardInHand(hand, Rainstorm)
        if (rainstorm) {
            this.scoreBonus += 30
            addScoringInfo('gainsByCard', this, rainstorm, 30)
        }
    }
}
let FireElemental = {
    name: "FireElemental",
    suit: suits.Flame,
    baseStrength: 4,
    relationType: "none",
    applyBonus({hand}) {
        for (let card of hand) {
            if (card.scoreSuit === suits.Flame && card !== this) {
                this.scoreBonus += 15
                addScoringInfo('gainsBySuit', this, card, 15, suits.Flame)
            }
        }
    }
}

let CelestialKnights = {
    name: "Knights",
    suit: suits.Army,
    hasPenalty: true,
    baseStrength: 20,
    relationType: "none",
    applyPenalties(hand) {
        if (hand.filter(c => c.scoreSuit === suits.Leader).length === 0) {
            this.scoreBonus -= 8
            addScoringInfo('loosesByMissingSuit', this, null, -8, suits.Leader)
        }
    }
}
let ElvenArchers = {
    name: "ElvenArchers",
    suit: suits.Army,
    baseStrength: 10,
    relationType: "none",
    applyBonus({hand}) {
        if (hand.filter(c => c.scoreSuit === suits.Weather).length === 0) {
            this.scoreBonus += 5
            addScoringInfo('gainsByMissingSuit', this, null, 5, suits.Weather)
        }
    }
}
let LightCavalry = {
    name: "LightCavalry",
    suit: suits.Army,
    hasPenalty: true,
    baseStrength: 17,
    relationType: "none",
    applyPenalties(hand) {
        for (let card of hand) {
            if (card.scoreSuit === suits.Land) {
                this.scoreBonus -= 2
                addScoringInfo('loosesBySuit', this, card, -2, card.scoreSuit)
            }
        }
    }
}
let DwarvishInfantry = {
    name: "DwarvishInfantry",
    suit: suits.Army,
    hasPenalty: true,
    hasArmyPenalty: true,
    baseStrength: 15,
    relationType: "none",
    applyPenalties(hand) {
        if (!this.armyPenaltyCleared) {
            for (let card of hand) {
                if (card.scoreSuit === suits.Army && this !== card) {
                    this.scoreBonus -= 2
                    addScoringInfo('loosesBySuit', this, card, -2, card.scoreSuit)
                }
            }
        }
    }
}
let Rangers = {
    name: "Rangers",
    suit: suits.Army,
    deck: decks.Basic,
    baseStrength: 5,
    relationType: "none",
    clearPenalties(hand) {
        for (let card of hand) {
            if (card.hasArmyPenalty) {
                card.armyPenaltyCleared = true
                addScoringInfo('clearsArmyPenalty', this, card, null, suits.Army)
            }
        }
    },
    applyBonus({hand}) {
        for (let card of hand) {
            if (card.scoreSuit === suits.Land) {
                this.scoreBonus += 10
                addScoringInfo('gainsBySuit', this, card, 10, suits.Land)
            }
        }
    }
}
let RangersCH = {
    ...Rangers,
    imgName: 'RangersCH',
    deck: decks.CursedHoard,
    applyBonus({hand}) {
        Rangers.applyBonus.call(this, {hand})
        for (let card of hand) {
            if (card.scoreSuit === suits.Building) {
                this.scoreBonus += 10
                addScoringInfo('gainsBySuit', this, card, 10, card.scoreSuit)
            }
        }
    }
}

let Collector = {
    name: "Collector",
    suit: suits.Wizard,
    baseStrength: 7,
    relationType: "none",
    applyBonus({hand}) {
        const bonuses = [10, 40, 100, 100, 100]
        let suitsCovered = []
        for (let card of hand) {
            if (suitsCovered.filter(coveredSuit => card.scoreSuit === coveredSuit).length === 0) {
                // new suit to count
                const suit = card.scoreSuit
                suitsCovered.push(suit)
                const foundCards = new Set()
                foundCards.add(card)
                for (let c of hand) {
                    if (c.scoreSuit === suit
                        && ([...foundCards].filter(fc => fc.scoreName === c.scoreName).length === 0))
                        foundCards.add(c)
                }
                if (foundCards.size >= 3) {
                    const bonus = bonuses[foundCards.size - 3]
                    this.scoreBonus += bonus
                    addScoringInfo('gainsByStreet', this, null, bonus, suit, null, null, foundCards.size)
                    foundCards.forEach(c =>
                        addScoringInfo('contributesToCollection', c, this, bonus, suit))
                }
            }
        }
    }
}

let Beastmaster = {
    name: "Beastmaster",
    suit: suits.Wizard,
    baseStrength: 9,
    relationType: "none",
    clearPenalties(hand) {
        for (let card of hand) {
            if (card.scoreSuit === suits.Beast) {
                card.penaltiesCleared = true
                addScoringInfo('clearsPenalty', this, card)
            }
        }
    },
    applyBonus({hand}) {
        for (let card of hand) {
            if (card.scoreSuit === suits.Beast) {
                this.scoreBonus += 9
                addScoringInfo('gainsBySuit', this, card, 9, suits.Beast)
            }
        }
    }
}
let WarlockLord = {
    name: "WarlockLord",
    suit: suits.Wizard,
    hasPenalty: true,
    baseStrength: 25,
    relationType: "none",
    applyPenalties(hand) {
        for (let card of hand) {
            if (this !== card
                && (card.scoreSuit === suits.Leader
                    || card.scoreSuit === suits.Wizard)) {
                this.scoreBonus -= 10
                addScoringInfo('loosesBySuit', this, card, -10, card.scoreSuit)
            }
        }
    }
}
let ElementalEnchantress = {
    name: "Enchantress",
    suit: suits.Wizard,
    baseStrength: 5,
    relationType: "none",
    applyBonus({hand}) {
        for (let card of hand) {
            if (card.scoreSuit === suits.Land
                || card.scoreSuit === suits.Weather
                || card.scoreSuit === suits.Flood
                || card.scoreSuit === suits.Flame) {
                this.scoreBonus += 5
                addScoringInfo('gainsBySuit', this, card, 5, card.scoreSuit)
            }
        }
    }
}
let Necromancer = {
    name: "Necromancer",
    suit: suits.Wizard,
    deck: decks.Basic,
    baseStrength: 3,
    relationType: "none",
    extendsHandBy: 1
}
let NecromancerCH = {
    ...Necromancer,
    deck: decks.CursedHoard,
    imgName: 'NecromancerCH',
    clearPenalties(hand) {
        for (let card of hand)
            if (card.scoreSuit === 'Undead') {
                card.unblankable = true
                addScoringInfo('makesUnblankable', this, card)
            }
    }
}
let Jester = {
    name: "Jester",
    suit: suits.Wizard,
    relationType: 'none',
    baseStrength: 3,
    applyBonus({hand}) {
        let oddCards = hand.filter(c =>
            // for blanked cards we take the baseStrength (https://boardgamegeek.com/thread/1841490/jester-blanked-card)
            // for non-blanked cards we will have to take the scoreBaseStrength (so that the Doppelgaenger, for instance, works with this card)
            // as blanked card have a scoreBaseStrength of 0 in this implementation we have to implement it like this
            (c.blanked ? c.baseStrength : c.scoreBaseStrength) % 2 === 1)
        if (oddCards.length === hand.length) {
            // only odd cards
            this.scoreBonus = 50
            addScoringInfo('gainsByAllCardsHavingOddBaseStrength', this, null, 50)
        } else {
            for (let c of oddCards) {
                if (c.name !== Jester.name) {
                    this.scoreBonus += 3
                    addScoringInfo('gainsByOddBaseStrength', this, c, 3)
                }
            }
        }
    }
}

let King = {
    name: "King",
    suit: suits.Leader,
    baseStrength: 8,
    relationType: "none",
    applyBonus({hand}) {
        let bonusAdd = findCardInHand(hand, Queen) ? 20 : 5
        for (let card of hand) {
            if (card.scoreSuit === suits.Army) {
                this.scoreBonus += bonusAdd
                addScoringInfo('gainsBySuit', this, card, bonusAdd, suits.Army)
            }
        }
    }
}
let Queen = {
    name: "Queen",
    suit: suits.Leader,
    baseStrength: 6,
    relationType: "none",
    applyBonus({hand}) {
        let bonusAdd = findCardInHand(hand, King) ? 20 : 5
        for (let card of hand) {
            if (card.scoreSuit === suits.Army) {
                this.scoreBonus += bonusAdd
                addScoringInfo('gainsBySuit', this, card, bonusAdd, suits.Army)
            }
        }
    }
}
let Princess = {
    name: "Princess",
    suit: suits.Leader,
    baseStrength: 2,
    relationType: "none",
    applyBonus({hand}) {
        for (let card of hand) {
            if (card !== this
                && (card.scoreSuit === suits.Leader
                    || card.scoreSuit === suits.Wizard
                    || card.scoreSuit === suits.Army)) {
                this.scoreBonus += 8
                addScoringInfo('gainsBySuit', this, card, 8, card.scoreSuit)
            }
        }
    }
}
let Warlord = {
    name: "Warlord",
    suit: suits.Leader,
    baseStrength: 4,
    relationType: "none",
    applyBonus({hand}) {
        for (let card of hand) {
            if (card.scoreSuit === suits.Army) {
                this.scoreBonus += card.scoreBaseStrength
                addScoringInfo('gainsByBaseStrength', this, card, card.scoreBaseStrength)
            }
        }
    }
}
let Empress = {
    name: "Empress",
    suit: suits.Leader,
    baseStrength: 15,
    hasPenalty: true,
    relationType: "none",
    applyPenalties(hand) {
        for (let card of hand) {
            if (card !== this && card.scoreSuit === suits.Leader) {
                this.scoreBonus -= 5
                addScoringInfo('loosesBySuit', this, card, -5, suits.Leader)
            }
        }
    },
    applyBonus({hand}) {
        for (let card of hand) {
            if (card.scoreSuit === suits.Army) {
                this.scoreBonus += 10
                addScoringInfo('gainsBySuit', this, card, 10, suits.Army)
            }
        }
    }
}

let Unicorn = {
    name: "Unicorn",
    suit: suits.Beast,
    baseStrength: 9,
    relationType: "none",
    applyBonus({hand}) {
        let princess = findCardInHand(hand, Princess)
        if (!princess) {
            let empress = findCardInHand(hand, Empress)
            let queen = findCardInHand(hand, Queen)
            let elementalEnchantress = findCardInHand(hand, ElementalEnchantress)
            let card = empress || queen || elementalEnchantress
            if (card) {
                this.scoreBonus = 15
                addScoringInfo('gainsByCard', this, card, 15)
            }
        } else {
            this.scoreBonus = 30
            addScoringInfo('gainsByCard', this, princess, 30)
        }
    }
}
let Basilisk = {
    name: "Basilisk",
    suit: suits.Beast,
    hasPenalty: true,
    hasArmyPenalty: true,
    baseStrength: 35,
    relationType: "none",
    blanks(hand) {
        if (this.penaltiesCleared)
            return []
        return hand.filter(card =>
            card !== this && !card.unblankable &&
            ((card.scoreSuit === 'Army' && !this.armyPenaltyCleared)
                || card.scoreSuit === 'Leader'
                || card.scoreSuit === 'Beast'))
    },
    applyPenalties(hand) {
        this.blanks(hand).forEach(card => {
            addScoringInfo('blanksBySuit', this, card, 0, card.scoreSuit)
            card.blank()
        })
    }
}
let Warhorse = {
    name: "Warhorse",
    suit: suits.Beast,
    baseStrength: 6,
    relationType: "none",
    applyBonus({hand}) {
        for (let card of hand) {
            if (card.scoreSuit === suits.Leader || card.scoreSuit === suits.Wizard) {
                this.scoreBonus = 14
                addScoringInfo('gainsBySuit', this, card, 14, card.scoreSuit)
                return
            }
        }
    }
}
let Dragon = {
    name: "Dragon",
    suit: suits.Beast,
    hasPenalty: true,
    baseStrength: 30,
    relationType: "none",
    applyPenalties(hand) {
        if (hand.filter(c => c.scoreSuit === suits.Wizard).length === 0) {
            this.scoreBonus -= 40
            addScoringInfo('loosesByMissingSuit', this, null, -40, suits.Wizard)
        }
    }
}
let Hydra = {
    name: "Hydra",
    suit: suits.Beast,
    baseStrength: 12,
    relationType: "none",
    applyBonus({hand}) {
        let swamp = findCardInHand(hand, Swamp)
        if (swamp) {
            this.scoreBonus = 28
            addScoringInfo('gainsByCard', this, swamp, 28)
        }
    }
}

let Warship = {
    name: "Warship",
    suit: suits.Weapon,
    hasPenalty: true,
    baseStrength: 23,
    relationType: "none",
    blanks(hand) {
        if (this.penaltiesCleared || this.unblankable)
            return []
        if (hand.filter(c => c.scoreSuit === suits.Flood).length === 0)
            return [this]
        return []
    },
    applyPenalties(hand) {
        if (this.blanks(hand).length > 0) {
            addScoringInfo('requiredSuitForUnblockMissing', this, null, 0, suits.Flood)
            this.blank()
        }
    },
    clearPenalties(hand) {
        for (let card of hand.filter(c => c.scoreSuit === suits.Flood && c.hasArmyPenalty)) {
            card.armyPenaltyCleared = true
            addScoringInfo('clearsArmyPenalty', this, card, 0, suits.Army)
        }
    }
}
let MagicWand = {
    name: "MagicWand",
    suit: suits.Weapon,
    baseStrength: 1,
    relationType: "none",
    applyBonus({hand}) {
        for (let card of hand) {
            if (card.scoreSuit === suits.Wizard) {
                this.scoreBonus = 25
                addScoringInfo('gainsBySuit', this, card, 25, suits.Wizard)
            }
        }
    }
}
let SwordOfKeth = {
    name: "SwordOfKeth",
    suit: suits.Weapon,
    baseStrength: 7,
    relationType: "none",
    applyBonus({hand}) {
        let leader = hand.find(card => card.scoreSuit === suits.Leader)
        if (leader) {
            let shieldOfKeth = findCardInHand(hand, ShieldOfKeth)
            this.scoreBonus = shieldOfKeth ? 40 : 10
            let scoringInfoCode = this.scoreBonus === 10 ? 'gainsBySuit' : 'gainsBySuitAndCard'
            addScoringInfo(scoringInfoCode, this, leader, this.scoreBonus, suits.Leader, shieldOfKeth)
        }
    }
}
let ElvenLongbow = {
    name: "ElvenLongbow",
    suit: suits.Weapon,
    baseStrength: 3,
    relationType: "none",
    applyBonus({hand}) {
        let e = findCardInHand(hand, ElvenArchers)
        let w = findCardInHand(hand, Warlord)
        let b = findCardInHand(hand, Beastmaster)
        if (e || w || b) {
            this.scoreBonus = 30
            addScoringInfo('gainsByCard', this, e || w || b, 30)
        }
    }
}
let WarDirigible = {
    name: "WarDirigible",
    suit: suits.Weapon,
    hasPenalty: true,
    hasArmyPenalty: true,
    baseStrength: 35,
    relationType: "none",
    blanks(hand) {
        if (this.unblankable)
            return []
        if ((!this.armyPenaltyCleared && hand.filter(card => card.scoreSuit === suits.Army).length === 0)
            || hand.filter(card => card.scoreSuit === suits.Weather).length > 0)
            return [this]
        return []
    },
    applyPenalties(hand) {
        if (this.blanks(hand).length > 0) {
            let weather = hand.find(c => c.scoreSuit === suits.Weather)
            if (weather) {
                addScoringInfo('blankedBySuit', this, weather, null, suits.Weather)
                this.blank()
            } else {
                addScoringInfo('requiredSuitForUnblockMissing', this, null, null, suits.Army)
                this.blank()
            }
        }
    }
}

let ShieldOfKeth = {
    name: "ShieldOfKeth",
    suit: suits.Artifact,
    baseStrength: 4,
    relationType: "none",
    applyBonus({hand}) {
        let leader = hand.find(card => card.scoreSuit === suits.Leader)
        if (leader) {
            let swordOfKeth = findCardInHand(hand, SwordOfKeth)
            this.scoreBonus = swordOfKeth ? 40 : 15
            let scoringInfoCode = this.scoreBonus === 10 ? 'gainsBySuit' : 'gainsBySuitAndCard'
            addScoringInfo(scoringInfoCode, this, leader, this.scoreBonus, suits.Leader, swordOfKeth)
        }
    }
}
let GemOfOrder = {
    name: "GemOfOrder",
    suit: suits.Artifact,
    baseStrength: 5,
    relationType: "none",
    applyBonus({hand}) {
        const bonusesPerRunLength = [10, 30, 60, 100, 150]
        let orderedHand = [...hand]
            .filter(c => !c.blanked)
            .sort((c1, c2) => c1.scoreBaseStrength - c2.scoreBaseStrength)
        while (orderedHand.length > 0) {
            let cardsUsedForStreet = []
            let cardsOfLongestRun = []
            let longestRun = 0
            let currentRun = 0
            let lastStrength = -2
            for (let card of orderedHand) {
                let strength = card.scoreBaseStrength
                if (strength !== lastStrength) {
                    if (strength !== lastStrength + 1) {
                        currentRun = 1
                        longestRun = 1
                        cardsUsedForStreet = [card]
                        cardsOfLongestRun = [card]
                    } else {
                        currentRun++
                        cardsUsedForStreet.push(card)
                    }
                    if (currentRun > longestRun) {
                        longestRun = currentRun
                        cardsOfLongestRun = cardsUsedForStreet
                    }
                    lastStrength = strength
                }
            }
            if (longestRun >= 3) {
                const bonus = bonusesPerRunLength[Math.min(longestRun, 7) - 3]
                this.scoreBonus += bonus
                addScoringInfo('gainsByStreet', this, null, bonus, null, null, null, longestRun)
                for (let c of cardsOfLongestRun)
                    addScoringInfo('contributesToStreet', c, this, bonus)
            }
            orderedHand = orderedHand.filter(function (c) {
                return !cardsUsedForStreet.includes(c);
            });
        }
    }
}
let WorldTree = {
    name: "WorldTree",
    suit: suits.Artifact,
    deck: decks.Basic,
    baseStrength: 2,
    relationType: "none",
    applyBonus({hand, bonus}) {
        let foundSoFar = new Set()
        for (let card of hand.filter(card => !card.blanked)) {
            let suit = card.scoreSuit
            if (!foundSoFar.has(suit))
                foundSoFar.add(suit)
            else
                return
        }
        this.scoreBonus = bonus || 50
        addScoringInfo('gainsByDifferentSuits', this, null, 50)
    }
}
let WorldTreeCH = {
    ...WorldTree,
    imgName: 'WorldTreeCH',
    deck: decks.CursedHoard,
    applyBonus({hand}) {
        WorldTree.applyBonus.call(this, {hand, bonus: 70})
    }
}
let BookOfChanges = {
    name: "BookOfChanges",
    suit: suits.Artifact,
    baseStrength: 3,
    order: 4,
    relationType: "handCardAndSuit",
    applyRelation() {
        if (this.relatedCard && this.relatedSuit) {
            this.relatedCard.scoreSuit = this.relatedSuit
            addScoringInfo('changesSuit', this, this.relatedCard, 0, this.relatedSuit)
        }
    }
}
let ProtectionRune = {
    name: "ProtectionRune",
    suit: suits.Artifact,
    baseStrength: 1,
    relationType: "none",
    clearPenalties(hand) {
        for (let card of hand) {
            if (card.hasPenalty) {
                card.penaltiesCleared = true
                addScoringInfo('clearsPenalty', this, card)
            }
        }
    }
}

let Shapeshifter = {
    name: "Shapeshifter",
    suit: suits.Wild,
    deck: decks.Basic,
    baseStrength: 0,
    order: 3,
    relationType: "gameCard",
    relationAffectsSelf: true,
    relationSuits: [suits.Artifact, suits.Leader, suits.Wizard, suits.Weapon, suits.Beast],
    applyRelation() {
        if (this.relatedCard) {
            this.scoreName = this.relatedCard.name
            this.scoreSuit = this.relatedCard.suit
            addScoringInfo('copiesNameAndSuit', this, this.relatedCard)
        }
    }
}
let ShapeshifterCH = {
    ...Shapeshifter,
    imgName: 'ShapeshifterCH',
    deck: decks.CursedHoard,
    relationSuits: [...Shapeshifter.relationSuits, suits.Undead]
}
let Mirage = {
    name: "Mirage",
    suit: suits.Wild,
    deck: decks.Basic,
    baseStrength: 0,
    order: 2,
    relationType: "gameCard",
    relationAffectsSelf: true,
    relationSuits: [suits.Army, suits.Land, suits.Weather, suits.Flood, suits.Flame],
    applyRelation() {
        if (this.relatedCard) {
            this.scoreName = this.relatedCard.name
            this.scoreSuit = this.relatedCard.suit
            addScoringInfo('copiesNameAndSuit', this, this.relatedCard)
        }
    },
}
let MirageCH = {
    ...Mirage,
    imgName: 'MirageCH',
    deck: decks.CursedHoard,
    relationSuits: [...Mirage.relationSuits, suits.Building]
}
let Doppelgaenger = {
    name: "Doppelgaenger",
    suit: suits.Wild,
    baseStrength: 0,
    order: 1,
    relationType: "handCard",
    relationAffectsSelf: true,
    applyRelation() {
        if (this.relatedCard) {
            this.scoreName = this.relatedCard.name
            this.scoreBaseStrength = this.relatedCard.baseStrength
            this.scoreSuit = this.relatedCard.suit
            this.hasPenalty = this.relatedCard.hasPenalty
            this.hasArmyPenalty = this.relatedCard.hasArmyPenalty
            addScoringInfo('doppelgaengs', this, this.relatedCard)
        } else {
            this.scoreName = this.name
            this.scoreBaseStrength = 0
            this.scoreSuit = this.suit
            this.hasPenalty = false
            this.hasArmyPenalty = false
        }
    },
    blanks(hand) {
        if (this.relatedCard && this.relatedCard.blanks !== undefined)
            return this.relatedCard.blanks.call(this, hand)
        return []
    },
    applyPenalties(hand) {
        if (this.relatedCard && this.relatedCard.applyPenalties !== undefined)
            return this.relatedCard.applyPenalties.call(this, hand)
    }
}

let Dungeon = {
    name: 'Dungeon',
    suit: 'Building',
    deck: decks.CursedHoard,
    baseStrength: 7,
    relationType: 'none',
    applyBonus({hand}) {
        for (const suit of [suits.Undead, suits.Beast, suits.Artifact]) {
            let cardsOfSuitInHand = hand.filter(c => c.scoreSuit === suit)
            for (const [idx, card] of cardsOfSuitInHand.entries()) {
                let bonusAdd = idx === 0 ? 10 : 5
                this.scoreBonus += bonusAdd
                addScoringInfo('gainsBySuit', this, card, bonusAdd, suit)
            }
        }
        for (const cardToFind of [Necromancer, Warlord, Demon]) {
            for (let foundCard of hand.filter(card => card.scoreName === cardToFind.name)) {
                this.scoreBonus += 5
                addScoringInfo('gainsByCard', this, foundCard, 5)
            }
        }
    }
}
let Castle = {
    name: 'Castle',
    suit: 'Building',
    deck: decks.CursedHoard,
    baseStrength: 10,
    relationType: 'none',
    applyBonus({hand}) {
        for (const suit of [suits.Leader, suits.Army, suits.Land, suits.Building]) {
            let cardsOfSuitInHand = hand.filter(c => c.scoreSuit === suit && c.name !== this.name)
            for (const [idx, card] of cardsOfSuitInHand.entries()) {
                let bonusAdd = idx === 0 ? 10 : (suit === suits.Building ? 5 : 0)
                if (bonusAdd > 0) {
                    this.scoreBonus += bonusAdd
                    addScoringInfo('gainsBySuit', this, card, bonusAdd, suit)
                }
            }
        }
    }
}
let Crypt = {
    name: 'Crypt',
    suit: 'Building',
    deck: decks.CursedHoard,
    baseStrength: 21,
    hasPenalty: true,
    relationType: 'none',
    blanks(hand) {
        return hand.filter(card => card.scoreSuit === suits.Leader && !card.unblankable)
    },
    applyPenalties(hand) {
        for (const card of this.blanks(hand)) {
            card.blank()
            addScoringInfo('blanksBySuit', this, card, 0, suits.Leader)
        }
    },
    applyBonus({hand}) {
        for (const card of hand.filter(c => c.scoreSuit === suits.Undead)) {
            this.scoreBonus += card.scoreBaseStrength
            addScoringInfo('gainsByBaseStrength', this, card, card.scoreBaseStrength)
        }
    }
}
let Chapel = {
    name: 'Chapel',
    suit: 'Building',
    deck: decks.CursedHoard,
    baseStrength: 2,
    relationType: 'none',
    applyBonus({hand}) {
        let cardsFound = hand.filter(card => [suits.Leader, suits.Wizard, suits.Outsider, suits.Undead].includes(card.scoreSuit))
        if (cardsFound.length === 2) {
            this.scoreBonus += 40
            addScoringInfo('gainsByExactlyTwoCards', this, cardsFound[0], 40, null, cardsFound[1])
            addScoringInfo('contributesToBonus', cardsFound[0], this, 40)
            addScoringInfo('contributesToBonus', cardsFound[1], this, 40)
        }
    }
}
let BellTowerCH = {
    ...BellTower,
    suit: 'Building',
    deck: decks.CursedHoard,
    imgName: 'BellTowerCH',
    applyBonus({hand}) {
        for (let c of hand) {
            if (c.scoreSuit === suits.Wizard || c.scoreSuit === suits.Undead) {
                this.scoreBonus = 15
                addScoringInfo('gainsBySuit', this, c, 15, c.scoreSuit)
                return
            }
        }
    }
}

let Genie = {
    name: 'Genie',
    suit: 'Outsider',
    deck: decks.CursedHoard,
    baseStrength: -50,
    relationType: 'none',
    extendsHandBy: 1,
    applyBonus({players}) {
        if (players.length > 1) {
            this.scoreBonus = (players.length - 1) * 10
            addScoringInfo('gainsByPlayers', this, null, this.scoreBonus, null, null, null, players.length - 1)
        }
    }
}
let Judge = {
    name: 'Judge',
    suit: 'Outsider',
    deck: decks.CursedHoard,
    baseStrength: 11,
    relationType: 'none',
    applyBonus({hand}) {
        for (let card of hand) {
            if (card.hasPenalty && !card.penaltiesCleared) {
                this.scoreBonus += 10
                addScoringInfo('gainsByPenaltyNotCleared', this, card, 10)
            }
        }
    }
}
let Angel = {
    name: 'Angel',
    suit: 'Outsider',
    deck: decks.CursedHoard,
    baseStrength: 16,
    relationType: 'handCard',
    unblankable: true,
    applyRelation() {
        if (this.relatedCard) {
            this.relatedCard.unblankable = true
            addScoringInfo('makesUnblankable', this, this.relatedCard)
        }
    }
}
let Leprechaun = {
    name: 'Leprechaun',
    suit: 'Outsider',
    deck: decks.CursedHoard,
    baseStrength: 20,
    relationType: 'none',
    extendsHandBy: 1
}
let Demon = {
    name: 'Demon',
    suit: 'Outsider',
    deck: decks.CursedHoard,
    baseStrength: 45,
    relationType: 'none',
    hasPenalty: true,
    blanksFirst: true,
    blanks(hand) {
        let cardsToBlank = []
        if (this.penaltiesCleared)
            return cardsToBlank
        for (const suit in suits) {
            if (suit !== suits.Outsider) {
                let cardsOfSuitInHand = hand.filter(c => c.scoreSuit === suit)
                if (cardsOfSuitInHand.length === 1) {
                    let cardToBlank = cardsOfSuitInHand[0]
                    if (!cardToBlank.unblankable)
                        cardsToBlank.push(cardToBlank)
                }
            }
        }
        return cardsToBlank
    },
    applyPenalties(hand) {
        for (const card of this.blanks(hand)) {
            addScoringInfo('blanksOnlyInSuit', this, card, 0, card.scoreSuit)
            card.blank()
        }
    }
}

let DarkQueen = {
    name: 'DarkQueen',
    suit: 'Undead',
    deck: decks.CursedHoard,
    baseStrength: 10,
    relationType: 'none',
    applyBonus({discard}) {
        let unicorn = findCardInDiscard(discard, Unicorn)
        if (unicorn) {
            this.scoreBonus += 5
            addScoringInfo('gainsByCardInDiscard', this, unicorn, 5)
        }
        discard.filter(c => [suits.Land, suits.Flood, suits.Weather, suits.Flame].indexOf(c.suit) >= 0).forEach(c => {
            this.scoreBonus += 5
            addScoringInfo('gainsBySuitInDiscard', this, c, 5, c.suit)
        })
    }
}
let Ghoul = {
    name: 'Ghoul',
    suit: 'Undead',
    deck: decks.CursedHoard,
    baseStrength: 8,
    relationType: 'none',
    applyBonus({discard}) {
        discard.filter(c => [suits.Wizard, suits.Leader, suits.Army, suits.Beast, suits.Undead].indexOf(c.suit) >= 0)
            .forEach(c => {
                this.scoreBonus += 4
                addScoringInfo('gainsBySuitInDiscard', this, c, 4, c.suit)
            })
    }
}
let Specter = {
    name: 'Specter',
    suit: 'Undead',
    deck: decks.CursedHoard,
    baseStrength: 12,
    relationType: 'none',
    applyBonus({discard}) {
        discard.filter(c => [suits.Wizard, suits.Artifact, suits.Outsider].indexOf(c.suit) >= 0)
            .forEach(c => {
                this.scoreBonus += 6
                addScoringInfo('gainsBySuitInDiscard', this, c, 6, c.suit)
            })
    }
}
let Lich = {
    name: 'Lich',
    suit: 'Undead',
    deck: decks.CursedHoard,
    baseStrength: 13,
    relationType: 'none',
    clearPenalties(hand) {
        for (let card of hand)
            if (card.scoreSuit === 'Undead') {
                card.unblankable = true
                addScoringInfo('makesUnblankable', this, card)
            }
    },
    applyBonus({hand}) {
        let necromancer = findCardInHand(hand, Necromancer)
        if (necromancer) {
            this.scoreBonus += 10
            addScoringInfo('gainsByCard', this, necromancer, 10)
        }
        hand.filter(c => c.scoreSuit === suits.Undead && c.name !== this.name).forEach(c => {
            this.scoreBonus += 10
            addScoringInfo('gainsBySuit', this, c, 10, c.scoreSuit)
        })
    }
}
let DeathKnight = {
    name: 'DeathKnight',
    suit: 'Undead',
    deck: decks.CursedHoard,
    baseStrength: 14,
    relationType: 'none',
    applyBonus({discard}) {
        discard.filter(c => [suits.Weapon, suits.Army].indexOf(c.suit) >= 0)
            .forEach(c => {
                this.scoreBonus += 7
                addScoringInfo('gainsBySuitInDiscard', this, c, 7, c.suit)
            })
    }
}

export const cards = {
    // Land
    Mountain,
    UndergroundCaverns,
    BellTower,
    Forest,
    EarthElemental,
    Garden,
    // Flood
    FountainOfLife,
    Swamp,
    GreatFlood,
    Island,
    WaterElemental,
    FountainOfLifeCH,
    GreatFloodCH,
    // Weather
    Rainstorm,
    Blizzard,
    Smoke,
    AirElemental,
    Whirlwind,
    // Flames
    Wildfire,
    Candle,
    Forge,
    Lightning,
    FireElemental,
    // Army
    CelestialKnights,
    ElvenArchers,
    DwarvishInfantry,
    LightCavalry,
    Rangers,
    RangersCH,
    // Wizard
    Collector,
    Beastmaster,
    WarlockLord,
    ElementalEnchantress,
    Necromancer,
    NecromancerCH,
    Jester,
    //Leader
    King,
    Queen,
    Princess,
    Warlord,
    Empress,
    // Beast
    Unicorn,
    Basilisk,
    Warhorse,
    Dragon,
    Hydra,
    // Weapon
    Warship,
    MagicWand,
    ElvenLongbow,
    SwordOfKeth,
    WarDirigible,
    // Artifact
    ShieldOfKeth,
    GemOfOrder,
    WorldTree,
    WorldTreeCH,
    BookOfChanges,
    ProtectionRune,
    // Wild
    Shapeshifter,
    ShapeshifterCH,
    Mirage,
    MirageCH,
    Doppelgaenger,
    // Buildings
    Dungeon,
    Castle,
    Crypt,
    BellTowerCH,
    Chapel,
    // Outsider
    Genie,
    Judge,
    Angel,
    Leprechaun,
    Demon,
    // Undead
    DarkQueen,
    Ghoul,
    Specter,
    Lich,
    DeathKnight,
}

export const cursedItems = {
    Spyglass: {
        name: 'Spyglass',
        number: 24,
        points: -1
    },
    Sarcophagus: {
        name: 'Sarcophagus',
        number: 25,
        points: 5
    },
    Blindfold: {
        name: 'Blindfold',
        number: 26,
        points: 5
    },
    BookOfProphecy: {
        name: 'BookOfProphecy',
        number: 27,
        points: -1
    },
    CrystalBall: {
        name: 'CrystalBall',
        number: 28,
        points: -1
    },
    MarketWagon: {
        name: 'MarketWagon',
        number: 29,
        points: -1
    },
    Backpack: {
        name: 'Backpack',
        number: 30,
        points: -2
    },
    Shovel: {
        name: 'Shovel',
        number: 31,
        points: -2
    },
    SealedVault: {
        name: 'SealedVault',
        number: 32,
        points: -4
    },
    CrystalLens: {
        name: 'CrystalLens',
        number: 33,
        points: -2
    },
    LarcenousGloves: {
        name: 'LarcenousGloves',
        number: 34,
        points: -3
    },
    JunkyardMap: {
        name: 'JunkyardMap',
        number: 35,
        points: -3
    },
    WingedBoots: {
        name: 'WingedBoots',
        number: 36,
        points: -4
    },
    StaffOfTransmutation: {
        name: 'StaffOfTransmutation',
        number: 37,
        points: -4
    },
    Rake: {
        name: 'Rake',
        number: 38,
        points: -4
    },
    TreasureChest: {
        name: 'TreasureChest',
        number: 39,
        points: -5
    },
    Fishhook: {
        name: 'Fishhook',
        number: 40,
        points: -5
    },
    RepairKit: {
        name: 'RepairKit',
        number: 41,
        points: -6
    },
    Hourglass: {
        name: 'Hourglass',
        number: 42,
        points: -7
    },
    GoldMirror: {
        name: 'GoldMirror',
        number: 43,
        points: -8
    },
    Cauldron: {
        name: 'Cauldron',
        number: 44,
        points: -9
    },
    Lantern: {
        name: 'Lantern',
        number: 45,
        points: -10
    },
    Portal: {
        name: 'Portal',
        number: 46,
        points: -20
    },
    WishingRing: {
        name: 'WishingRing',
        number: 47,
        points: -20
    }
}

const addScoringInfo = function (code, card, refCard, bonus, suit, refCard2, refCard3, amount) {
    const scoringInfo = {
        code,
        card,
        refCard,
        bonus,
        suit,
        refCard2,
        refCard3,
        amount
    }
    card.scoringInfos.push(scoringInfo)
    if (card !== refCard
        && i18n.te('scoring.' + code + '_rev')) {
        [refCard, refCard2, refCard3].forEach(refCard =>
            refCard?.scoringInfos.push({
                ...scoringInfo,
                code: code + '_rev',
            }))
    }
}

const findCardInHand = function (hand, card) {
    return hand.find(c => c.scoreName === card.name)
}

const findCardInDiscard = function (discard, card) {
    return discard.find(c => c.name === card.name)
}
