<template>
  <icon icon="microphone" size="2x"/>
</template>
<script>
import {mapGetters} from "vuex";
import vr from '@/locales/vr'

export default {
  props: {
    recording: Boolean
  },
  data() {
    return {
      recognition: undefined,
      recognizedWords: [],
    }
  },
  computed: {
    ...mapGetters(['allCards']),
    vrMap() {
      return vr[this.$i18n.locale]
    },
    keyWords() {
      let arrOfArr = Object.entries(this.vrMap).map(prop => prop[1].split(' '))
      return [].concat(...arrOfArr)
    },
    translationMap() {
      const res = {}
      for (let keyValuePair of Object.entries(this.vrMap)) {
        let keyWords = keyValuePair[1].split(' ')
        for (let keyWord of keyWords) {
          res[keyWord.toLowerCase()] = keyValuePair[0]
        }
      }
      return res
    }
  },
  methods: {
    start() {
      this.recognition.start()
    },
    stop() {
      this.recognition.stop()
    }
  },
  watch: {
    recording(newVal, oldVal) {
      if (!oldVal)
        this.start()
      else
        this.stop()
    }
  },
  beforeMount() {
    const VoiceRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
    const SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList
    const grammar = '#JSGF V1.0; card colors; public <fantasy-realm-card> = ' + this.keyWords.join(' | ') + ' ;'
    this.recognition = new VoiceRecognition();
    const speechRecognitionList = new SpeechGrammarList();
    speechRecognitionList.addFromString(grammar, 1);
    this.recognition.grammars = speechRecognitionList;
    this.recognition.continuous = true;
    this.recognition.lang = this.$i18n.locale;
    this.recognition.interimResults = true;
    this.recognition.maxAlternatives = 2;
    this.recognition.onresult = async event => {
      this.recognizedWords = []
      for (let r of event.results) {
        for (let t of r) {
          const transcript = t.transcript.trim().toLowerCase()
          let spokenWords = transcript.split(' ')
          spokenWords.forEach(w => this.$emit('spoken', w))
          for (let spokenWord of spokenWords) {
            if (spokenWord && spokenWord.length > 1 && spokenWord !== ' ') {
              let recognizedCardName = this.translationMap[spokenWord]
              if (recognizedCardName) {
                await this.$emit('recognized', recognizedCardName)
              }
            }
          }
        }
      }
    }
    this.recognition.onend = () => {
      this.$emit('end')
    }
  }
}
</script>