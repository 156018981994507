export default {
    "de": {
        "Rangers": "Waldläufer Wald Läufer",
        "ElvenArchers": "Elbenschützen Elben Schützen Schütze Elbe",
        "DwarvishInfantry": "Zwergeninfanterie Zwergen Infanterie Zwerg",
        "LightCavalry": "Leichte Kavallerie",
        "Knights": "Ritter",
        "ProtectionRune": "Rune Schutzes",
        "WorldTree": "Weltenbaum Welten Baum",
        "BookOfChanges": "Buch Veränderung",
        "ShieldOfKeth": "Schild",
        "GemOfOrder": "Juwel Ordnung",
        "Warhorse": "Schlachtross Schlacht Ross",
        "Unicorn": "Einhorn",
        "Hydra": "Hydra",
        "Dragon": "Drache",
        "Basilisk": "Basilisk",
        "Doppelgaenger": "Doppelgänger",
        "Candle": "Kerze",
        "FireElemental": "Feuerwesen",
        "Forge": "Schmiede",
        "Lightning": "Blitz",
        "Wildfire": "Buschfeuer Busch",
        "FountainOfLife": "Quelle Lebens",
        "WaterElemental": "Wasserwesen Wasser",
        "Island": "Insel",
        "Swamp": "Sumpf",
        "GreatFlood": "Große Flut",
        "EarthElemental": "Erdwesen",
        "Cavern": "Höhle",
        "Forest": "Wald",
        "BellTower": "Glockenturm Glocken Turm",
        "Mountain": "Gebirge",
        "Princess": "Prinzessin",
        "Warlord": "Kriegsherr",
        "Queen": "Königin",
        "King": "König",
        "Empress": "Kaiserin",
        "Mirage": "Spiegelung",
        "Shapeshifter": "Gestaltwandler",
        "MagicWand": "Zauberstab",
        "ElvenLongbow": "Elbischer Bogen elbisch",
        "SwordOfKeth": "Schwert",
        "Warship": "Kriegsschiff",
        "WarDirigible": "Kampfzeppelin Zeppelin",
        "AirElemental": "Luftwesen Luft",
        "Rainstorm": "Regensturm Regen",
        "Whirlwind": "Wirbelsturm Wirbel",
        "Smoke": "Rauch",
        "Blizzard": "Blizzard",
        "Necromancer": "Totenbeschwörer Beschwörer",
        "Enchantress": "Magierin",
        "Collector": "Sammler",
        "Beastmaster": "Herr Bestien Bestie",
        "WarlockLord": "Hexenmeister",
        "Jester": "Hofnarr",
        "Garden": "Garten",
        "Dungeon": "Verlies",
        "Castle": "Burg",
        "Crypt": "Gruft",
        "Chapel": "Kapelle",
        "Genie": "Dschinn Gin",
        "Judge": "Richter",
        "Angel": "Engel",
        "Leprechaun": "Kobold",
        "Demon": "Dämon",
        "DarkQueen": "Dunkle    ",
        "Ghoul": "Ghoul",
        "Specter": "Gespenst",
        "Lich": "Lich",
        "DeathKnight": "Todesritter"
    },
    "en": {
        "Rangers": "Rangers",
        "ElvenArchers": "Elven Archers",
        "DwarvishInfantry": "Dwarvish Infantry",
        "LightCavalry": "Light Cavalry",
        "Knights": "Knights",
        "ProtectionRune": "Protection Rune",
        "WorldTree": "World Tree",
        "BookOfChanges": "Book Changes",
        "ShieldOfKeth": "Shield",
        "GemOfOrder": "Gem Order",
        "Warhorse": "Warhorse",
        "Unicorn": "Unicorn",
        "Hydra": "Hydra",
        "Dragon": "Dragon",
        "Basilisk": "Basilisk",
        "Doppelgaenger": "Doppelgänger Doppelganger",
        "Candle": "Candle",
        "FireElemental": "Fire Elemental",
        "Forge": "Forge",
        "Lightning": "Lightning",
        "Wildfire": "Wildfire",
        "FountainOfLife": "Fountain Life",
        "WaterElemental": "Water Elemental",
        "Island": "Island",
        "Swamp": "Swamp",
        "GreatFlood": "Great Flood",
        "EarthElemental": "Earth Elemental",
        "Cavern": "Cavern",
        "Forest": "Forest",
        "BellTower": "Bell Tower",
        "Mountain": "Mountain",
        "Princess": "Princess",
        "Warlord": "Warlord",
        "Queen": "Queen",
        "King": "King",
        "Empress": "Empress",
        "Mirage": "Mirage",
        "Shapeshifter": "Shapeshifter",
        "MagicWand": "Magic Wand",
        "ElvenLongbow": "Elven Longbow",
        "SwordOfKeth": "Sword",
        "Warship": "Warship",
        "WarDirigible": "War Dirigible",
        "AirElemental": "Air Elemental",
        "Rainstorm": "Rainstorm",
        "Whirlwind": "Whirlwind",
        "Smoke": "Smoke",
        "Blizzard": "Blizzard",
        "Necromancer": "Necromancer",
        "Enchantress": "Enchantress",
        "Collector": "Collector",
        "Beastmaster": "Beastmaster",
        "WarlockLord": "Warlock Lord",
        "Jester": "Jester",
        "Dungeon": "Dungeon",
        "Castle": "Castle",
        "Crypt": "Crypt",
        "Chapel": "Chapel",
        "Genie": "Genie",
        "Judge": "Judge",
        "Angel": "Angel",
        "Leprechaun": "Leprechaun",
        "Demon": "Demon",
        "DarkQueen": "Dark",
        "Ghoul": "Ghoul",
        "Specter": "Specter",
        "Lich": "Lich",
        "DeathKnight": "Death",
        "Garden": "Garden"
    }
}