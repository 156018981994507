import {cards, decks, cursedItems} from "@/logic/cards";
import scoringService from '@/logic/scoring-service'
import i18n from "@/i18n";

const findCardByName = (cards, cardName) => {
    return cards.find(c => c.name === cardName)
}
const findCardRelatingTo = (cards, cardName) => {
    return cards.find(c => c.relatedCard?.name === cardName)
}
const getCardsInPile = (cards, pile) => {
    return cards.filter(c => c.pile === pile)
}
const getResetCardsByDeck = (cards, deck) => {
    let cardsInDeck = Object.values(cards).filter(c => {
        return !c.deck || c.deck === deck
    })
    cardsInDeck.forEach(c => {
        c.scoreBonus = 0
        c.pile = -1
        c.relatedCard = null
        c.relatedSuit = null
    })
    return cardsInDeck
}

const getResetCursedItems = function () {
    for (const item of Object.values(cursedItems)) {
        item.pile = -1
    }
    return Object.values(cursedItems)
}


export default {
    state: {
        deck: decks.Basic,
        allCards: [],
        selectedPile: 0,
        selectedCard: undefined,
        cursedItems: getResetCursedItems()
    },
    mutations: {
        allCards(state, cards) {
            state.allCards = cards
        },
        selectedPile(state, pile) {
            state.selectedPile = pile
        },
        selectedCard(state, card) {
            state.selectedCard = card
        },
        deck(state, deck) {
            state.deck = deck
        },
        cursedItems(state, items) {
            state.cursedItems = items
        }
    },
    actions: {
        reset({commit, rootGetters}) {
            commit('deck', rootGetters.deck)
            commit('allCards', getResetCardsByDeck(cards, rootGetters.deck))
            commit('cursedItems', getResetCursedItems());
            commit('selectedCard', undefined)
            commit('selectedPile', 0)
        },
        async updateAllScores({dispatch, rootGetters}) {
            rootGetters.players.forEach(player => {
                dispatch('applyScoring', rootGetters.allCards.filter(c => c.pile === player.number))
            })
        },
        async applyScoring({dispatch, rootGetters}, hand) {
            if (hand.length > 0) {
                scoringService.applyScoring({hand, players: rootGetters.players, discard: rootGetters.discardPile})
                const pile = hand[0].pile
                let score = scoringService.score(hand, rootGetters.cursedItems.filter(ci => ci.pile === pile))
                await dispatch('setPlayerScore', {player: pile, score}, {root: true})
            }
        },
        async addCardToPile({dispatch, commit, state, getters}, {cardName, pile}) {
            let allCards = state.allCards
            let card = findCardByName(allCards, cardName)
            if (getters.cardsInSelectedPile.length === getters.maximumCardsInSelectedPile) {
                if (pile === 0 || (pile > 0 && (!card.extendsHandBy || card.extendsHandBy < 1)))
                    throw new Error(i18n.t('messages.pileCardLimitExceeded', {limit: getters.maximumCardsInSelectedPile}))
            }
            card.pile = pile
            await dispatch('updateAllScores')
            commit('allCards', allCards)
        },
        async resetCard({dispatch, getters}, cardName) {
            if (getters.selectedCard?.name === cardName) {
                // selected card is put back into deck -> navigate to next
                await dispatch('navigateCard', 1)
            }
            await dispatch('setCardRelation', {
                card: findCardRelatingTo(getters.allCards, cardName)?.name,
                relatedCard: null
            })
            await dispatch('addCardToPile', {cardName, pile: -1})
            // if this is still selected (last card in selectedPile -> unselect
            if (getters.selectedCard?.name === cardName)
                await dispatch('selectCard', null)
        },
        async setCardRelation({commit, dispatch, state}, {card, relatedCard, relatedSuit}) {
            let allCards = state.allCards
            let c = findCardByName(allCards, card)
            if (c) {
                c.relatedCard = relatedCard ? findCardByName(allCards, relatedCard) : null
                c.relatedSuit = relatedSuit
                await dispatch('applyScoring', getCardsInPile(allCards, state.selectedPile))
                commit('allCards', allCards)
            }
        },
        async playerRemoved({commit, getters}, playerNumber){
            const allCards = getters.allCards
            // all cards with pile === playerNumber will get pile = -1
            await allCards.filter(c => c.pile === playerNumber).forEach(c => c.pile = -1)
            // all cards with pile > playerNumber will get pile = pile-1
            await allCards.filter(c => c.pile > playerNumber).forEach(c => c.pile = c.pile -1)
            commit('allCards', allCards)
        },
        addCursedItem({commit, state, getters, dispatch}, cursedItem){
            if(state.selectedPile > 0){
                state.cursedItems.find(ci => ci.name === cursedItem.name).pile = state.selectedPile
                commit('cursedItems', state.cursedItems)
                dispatch('applyScoring', getters.cardsInSelectedPile)
            }
        },
        selectPlayer({dispatch}, playerId) {
            dispatch('selectPile', playerId)
        },
        selectPile({commit}, pile) {
            commit('selectedPile', pile)
        },
        async selectDiscard({dispatch}) {
            await dispatch('selectPile', 0)
        },
        async selectCard({dispatch, commit}, card) {
            if (card?.pile === 0)
                await dispatch('resetCard', card.name)
            else
                commit('selectedCard', card)
        },
        navigateCard({dispatch, getters}, indexAdd) {
            const mod = (n, m) => {
                return ((n % m) + m) % m
            }
            let currentIdx = getters.cardsInSelectedPile.findIndex(c => c.name === getters.selectedCard.name)
            let nextIdx = mod((currentIdx + indexAdd), getters.cardsInSelectedPile.length)
            return dispatch('selectCard', getters.cardsInSelectedPile[nextIdx])
        },
        playerAdded({commit, state}, players) {
            state.cursedItems.find(ci => ci === cursedItems.Spyglass).points = players.length === 2 ? -10 : -1
            commit('cursedItems', state.cursedItems)
        }
    },
    getters: {
        allCards(state) {
            return state.allCards
        },
        cursedItems(state) {
            return state.cursedItems
        },
        discardPile(state, getters) {
            return getters.allCards.filter(c => c.pile === 0)
        },
        allSuits(state, getters) {
            return Array.from(new Set(getters.allCards.map(c => c.suit)))
        },
        cardsInSelectedPile(state) {
            return getCardsInPile(state.allCards, state.selectedPile)
        },
        selectedPile(state) {
            return state.selectedPile
        },
        maximumCardsInSelectedPile(state, getters) {
            if (state.selectedPile === 0) {
                return 10
                    + (state.deck === decks.CursedHoard ? 2 : 0)
                    + (getters.players.length <= 2 ? 2 : 0)
            }
            if (state.selectedPile > 0) {
                // player pile
                let res = 7 + (state.deck === decks.CursedHoard ? 1 : 0)
                getters.cardsInSelectedPile.forEach(c => res += c.extendsHandBy || 0)
                return Math.min(9, res)
            }
        },
        selectedCard(state) {
            return state.selectedCard
        }
    }
}