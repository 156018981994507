let cookieConsent = {
    state: {
        technical: true,
        tracking: false,
        social: false,
        consent: false
    },
    mutations: {
        SET_CONSENT(state, consent) {
            state.consent = true
            state.technical = consent.technical
            state.social = consent.social
            state.tracking = consent.tracking
            localStorage.setItem('cookieConsent', JSON.stringify(state))
        }
    },
    getters: {
        consent(state){
          return state
        },
        technicalConsent(state) {
            return state.technical
        },
        trackingConsent(state) {
            return state.tracking
        }
    }
}

let storedConsentStr = localStorage.getItem('cookieConsent')
if (storedConsentStr)
    cookieConsent.state = JSON.parse(storedConsentStr)

export default cookieConsent
