import Vue from 'vue'
import Vuex from 'vuex'
import cardsAndPiles from './cards-and-piles'
import players from "@/store/players";
import games from "@/store/games"
import cookies from "@/store/cookies.store"
import {decks} from "@/logic/cards"

Vue.use(Vuex)
if (!localStorage.getItem('tutorial'))
    localStorage.setItem('tutorial', 'true')

export default new Vuex.Store({
    state: {
        tutorial: false, //Boolean(localStorage.getItem('tutorial')),
        cursedHoard: false,
        cursedItemsEnabled: false,
        extensionEnabled: false
    },
    mutations: {
        tutorial(state, tutorialState) {
            state.tutorial = tutorialState
            localStorage.setItem('tutorial', tutorialState)
        },
        cursedHoard(state, cursedHoard) {
            state.cursedHoard = cursedHoard
        },
        cursedItemsEnabled(state, cursedItemsEnabled) {
            state.cursedItemsEnabled = cursedItemsEnabled
        },
        extensionEnabled(state, extensionEnabled) {
            state.extensionEnabled = extensionEnabled
        }
    },
    actions: {
        toggleTutorial({commit, state}) {
            commit('tutorial', !state.tutorial)
        },
        toggleCursedHoard({commit, state}) {
            commit('cursedHoard', !state.cursedHoard)
        },
        toggleCursedItems({commit, state}) {
            commit('cursedItemsEnabled', !state.cursedItemsEnabled)
        },
        enableExtension({commit}) {
            commit('extensionEnabled', true)
        }
    },
    getters: {
        tutorial(state) {
            return state.tutorial
        },
        freshGame(state, getters) {
            return getters.players.length === 2 && getters.cardsInSelectedPile.length === 0
        },
        cursedHoard(state) {
            return state.cursedHoard
        },
        deck(state, getters) {
            return getters.cursedHoard ? decks.CursedHoard : decks.Basic
        },
        cursedItemsEnabled(state) {
            return state.cursedItemsEnabled
        },
        extensionEnabled(state) {
            return state.extensionEnabled
        }
    },
    modules: {
        cardsAndPiles, players, games, cookies
    }
})
