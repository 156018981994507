import Vue from 'vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faPlusCircle,
    faUser,
    faUserPlus,
    faWindowClose,
    faChevronLeft,
    faChevronRight,
    faEllipsisH,
    faAngleDoubleUp,
    faAngleDoubleDown,
    faHome,
    faRedoAlt,
    faUserEdit, faListOl, faGlobe,faMicrophone

} from '@fortawesome/free-solid-svg-icons'

library.add(faPlusCircle)
library.add(faUser)
library.add(faUserPlus)
library.add(faWindowClose)
library.add(faChevronLeft)
library.add(faChevronRight)
library.add(faEllipsisH)
library.add(faAngleDoubleDown)
library.add(faAngleDoubleUp)
library.add(faHome)
library.add(faRedoAlt)
library.add(faUserEdit)
library.add(faListOl)
library.add(faGlobe)
library.add(faMicrophone)

Vue.component('icon', FontAwesomeIcon)