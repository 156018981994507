import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue')
    },
    {
        path: '/pile',
        name: 'viewPiles',
        component: () => import('../views/ViewPiles.vue')
    },
    {
        path: '/add-cards',
        name: 'addToPile',
        component: () => import('../views/AddToPile.vue')
    },
    {
        path: '/edit-relation/:cardName',
        props: true,
        name: 'editRelation',
        component: () => import('../views/EditRelation.vue')
    },
    {
        path: '/imprint',
        name: 'imprint',
        component: () => import('../views/ViewImprint.vue')
    },
    {
        path: '/addtopile2',
        name: 'addToPile2',
        component: () => import('../views/AddToPile2'),
        meta: {
            test:false
        }
    },
    {
        path: '/players',
        name: 'managePlayers',
        component: () => import('../views/ManagePlayers'),
        meta: {
            test:false
        }
    },
    {
        path: '/statistics',
        name: 'viewStatistics',
        component: () => import('../views/ViewStatistics'),
        meta: {
            test:false
        }
    },
    {
        path: '/vr',
        name: 'voiceRecognition',
        component: () => import('../views/VoiceRecognitionView'),
        meta: {
            test:false
        }
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.test)
        next()
    else {
        if (store.getters.players.length == 0
            && !['home', 'imprint', 'viewStatistics', 'voiceRecognition'].includes(to.name))
            next('/')
        else
            next()
    }
})

export default router
