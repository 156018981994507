<template>
  <div class="pr" v-if="!understood" :style="{width: size, height: size}">
    <div class="pr-table">
      <div class="pr-content-wrapper">
        <div style="margin-bottom: 1rem;">
          <img src="/img/please-rotate.png" width="30%">
        </div>
        <div>{{ $t('pleaseRotateText') }}</div>
        <div @click="understood = true">
          <span class="button" style="display: inline-flex;margin-top: 0.6rem;">{{ $t('pleaseRotateButton') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      understood: false
    }
  },
  computed: {
    size(){
      return this.understood ? 0 : '100%'
    }
  }
}
</script>
<style scoped>
.pr {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: #000000CC;
  color: white;
  visibility: visible;
}

.pr-table {
  display: table;
  width: 100%;
  height: 100%;
  visibility: inherit;
}

.pr-content-wrapper {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

@media screen and (orientation: landscape) and (min-device-width: 480px) {
  .pr {
    visibility: hidden;
  }
}
</style>