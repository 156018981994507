import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/plugins/vue-fontawesome'
import '@/assets/custom.scss'
import i18n from './i18n'

Vue.config.productionTip = false

// Google Analytics
import VueGtag from "vue-gtag";

const streamId = (process.env.NODE_ENV === 'production') ? "G-0RNM6NMLMG" : "G-ZDB086P9PY"
Vue.use(VueGtag, {
    bootstrap: false,
    config: {
        id: streamId
    }
}, router)

import Toast, {POSITION} from 'vue-toastification'
import "vue-toastification/dist/index.css";

const options = {
    position: POSITION.BOTTOM_RIGHT
};
Vue.use(Toast, options);

const tooltipOptions =
    {
        defaultOffset: 5,
        // Default container where the tooltip will be appended
        defaultContainer: 'body',
        defaultBoundariesElement: undefined,
        defaultPopperOptions: {},
        // Class added when content is loading
        defaultLoadingClass: 'tooltip-loading',
        // Displayed when tooltip content is loading
        defaultLoadingContent: '...',
        // Hide on mouseover tooltip
        autoHide: true,
        // Close tooltip on click on tooltip target?
        defaultHideOnTargetClick: true,
        // Auto destroy tooltip DOM nodes (ms)
        disposeTimeout: 5000,
        // Options for popover
        popover: {
            defaultPlacement: 'bottom',
            // Use the `popoverClass` prop for theming
            defaultClass: 'vue-popover-theme',
            // Base class (change if conflicts with other libraries)
            defaultBaseClass: 'tooltip popover',
            // Wrapper class (contains arrow and inner)
            defaultWrapperClass: 'wrapper',
            // Inner content class
            defaultInnerClass: 'tooltip-inner popover-inner',
            // Arrow class
            defaultArrowClass: 'tooltip-arrow popover-arrow',
            // Class added when popover is open
            defaultOpenClass: 'open',
            defaultDelay: 0,
            defaultTrigger: 'click',
            defaultOffset: 0,
            defaultContainer: 'body',
            defaultBoundariesElement: undefined,
            defaultPopperOptions: {},
            // Hides if clicked outside of popover
            defaultAutoHide: true,
            // Update popper on content resize
            defaultHandleResize: true,
        },
    }
import VTooltip from 'v-tooltip'
Vue.use(VTooltip, tooltipOptions)

import messageMixin from '@/mixins/MessagesMixin'

Vue.mixin(messageMixin)
import tutorialMixin from '@/mixins/TutorialMixin'
Vue.mixin(tutorialMixin)

new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
