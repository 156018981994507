import Dexie from "dexie";

const db = new Dexie('fr');
db.version(1).stores({
    games: '++id, createdDate'
});

export default {
    state: {
        games: []
    },
    mutations: {
        games(state, games) {
            state.games = games
        }
    },
    actions: {
        async init({commit}) {
            await db.games.toArray(games => commit('games', games))
        },
        async saveGame({commit, getters}) {
            let players = getters.players
            let winner
            let winnerScore = 0
            players.forEach(p => {
                if (p.score > winnerScore) {
                    winner = p
                    winnerScore = p.score
                } else if (winner && p.score === winnerScore) {
                    let baseStrengthSumWinner =
                        getters.allCards.filter(c => c.pile === winner.number)
                            .map(c => c.scoreBaseStrength)
                            .reduce((sum, curr) => sum + curr, 0)
                    let baseStrengthSumPlayer =
                        getters.allCards.filter(c => c.pile === p.number)
                            .map(c => c.scoreBaseStrength)
                            .reduce((sum, curr) => sum + curr, 0)
                    if (baseStrengthSumPlayer > baseStrengthSumWinner) {
                        winner = p
                        winnerScore = p.score
                    } else if (baseStrengthSumPlayer === baseStrengthSumWinner) {
                        winnerScore = p.score
                        winner = undefined
                    }
                }
            })
            if (winner)
                winner.winner = true
            await db.games.add({
                createdDate: new Date(),
                players
            })
            commit('games', await db.games.toArray())
        },
        async resetStatistics({commit}) {
            db.games.clear()
            commit('games', [])
        }
    },
    getters: {
        allGames(state) {
            return state.games
        },
        lastGame(state) {
            return state.games[state.games.length - 1]
        }
    }
}
